import React, {
	useState,
	useCallback,
	useEffect,
	useMemo,
} from "react";

import {
	motion,
} from "framer-motion";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";

import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";


import ThemeData from "data/ThemeData";
import QueryData from "data/QueryData";
import ConstantData from "data/ConstantData";


import imgFunctions from "util/imgFunctions";


// import devLogo from "img/kindred_logo_darkFill_small.png";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			boxSizing: 'border-box',
			width: '100%',
			height: props.withPhotosOfCategoryName ? 'calc( 100vh - 100px ) ' : 'calc( 100vh - 200px )',

			padding: !props.noPadding ? 20 : 0,

			textAlign: 'left',

			overflowY: 'auto',

			...ThemeData.styles.thinScrollbarLightOnDark,
		} ),

		scroll: {
			color: 'white',
			position: 'absolute',
			height: '48px',
			width: '48px',
			bottom: '120px',
		},

		categoryButtons: {
			padding: '5px 15px 15px',
		},

		buttonGroup: {
			justifyContent: 'flex-start',
		},

		buttonOutline: {
			borderRadius: '50px',
			padding: '15px 15px',
		}
	},
	{
		name: "Gallery",
	}
)

const RenderGallery = ( data, error, classes, props ) => {

	if ( error ) {
		console.log( "Gallery Error:", error.message );
	}

	const [currentImage, setCurrentImage] = useState( 0 );
	const [isViewerOpen, setIsViewerOpen] = useState( false );
	const [showCategory, setShowCategory] = useState( ConstantData.galleryPhotoDefaultCategories.all );
	const [categories, setCategories] = useState( [] );

	const openLightbox = useCallback( ( e, { photo, index } ) => {
		setCurrentImage( photo.id );
		setIsViewerOpen( true );
	},[] );

	const closeLightbox = () => {
		setCurrentImage( 0 );
		setIsViewerOpen( false );
	};

	const validatePhotoCategories = ( photo ) => {
		if (props.section && props.section.slug == 'showhome') {
			return true;
		} else 
		if ( photo.gallery_photo_categories ) {
			if ( photo.gallery_photo_categories[0] === undefined || photo.gallery_photo_categories.length === 0 ) {
				return false;
			}

			return true
		}

		return false;
	};

	useEffect( () => {
		let categoryTypes = [ConstantData.galleryPhotoDefaultCategories.all];
		if (props.section && props.section.slug == 'showhome') {
			let images = props.section.content_pages[0].text_media_pairs
			images.map(( {colour} ) => {
				if ( !categoryTypes.includes( colour ) )
					categoryTypes.push( colour );
			})
		} else {
			if (data) {
			let addCategoryTypes = [...data.galleryPhotos].reduce( ( catBuild, photo ) => {
				photo.gallery_photo_categories && photo.gallery_photo_categories.forEach( cat => {
					if ( !catBuild.includes( cat.name ) )
						catBuild.push( cat.name );
				} )
				return catBuild;
			}, [] );
			categoryTypes = categoryTypes
				.concat(
					addCategoryTypes.sort( ( catNameA, catNameB ) => catNameA.localeCompare( catNameB ) )
				);
			}
		}

		setCategories( categoryTypes )
	}, [data] );

	const getCategories = (photo) => {
		if (props.section && props.section.slug == 'showhome') {
			return [photo.colour]
		} else {
			return photo.gallery_photo_categories.map( cat => cat.name ) 
		}
	}


	const galleryPhotos = useMemo( () => {
		let galleryPhotos = [{}];
		let photos = [];


		if (props.section && props.section.slug == 'showhome') {
			photos = props.section.content_pages[0].text_media_pairs
		} else if (data) {
			photos = data.galleryPhotos
		}
		
		if ( data )
			galleryPhotos =
				[...photos]
					.sort( ( phA, phB ) => phA.order - phB.order )
					.map( (photo, index) => {
						let image = '';
						if (props.section && props.section.slug == 'showhome') {
							image = photo.media
						} else {
							image = photo.image
						}

						return {
							id: index,
							title: photo.name,
							src: imgFunctions.GetImgBySizeUri( image, ConstantData.imgSizes.xlarge ),
							width: imgFunctions.GetImgBySizeWidth( image, ConstantData.imgSizes.medium ),
							height: imgFunctions.GetImgBySizeHeight( image, ConstantData.imgSizes.medium ),
							category: validatePhotoCategories( photo ) ? getCategories(photo) : ''
						};
					} )

		return galleryPhotos;
	}, [data] );


	const filteredPhotos = useMemo( () => {
		let filteredPhotos = [{}]		

		if ( filteredPhotos ) {
			filteredPhotos = galleryPhotos.filter( photo => {
				if ( showCategory === ConstantData.galleryPhotoDefaultCategories.all ) {
					return photo;
				} else if ( photo.category.includes( showCategory ) )
					return photo;

				return null;
			} );
		}

		// console.log( "fgp", filteredPhotos );

		return filteredPhotos;
	}, [showCategory, galleryPhotos] )


	// console.log( 'galleryPhotos', galleryPhotos );



	return (
		<div id="vertical">
			<div className={`${classes.wrapper} ${props.className}`}>
				{categories && categories.length > 1 &&
					<div className={classes.categoryButtons}>
						<ButtonGroup className={classes.buttonGroup}>
							{categories.map( ( category, idx ) =>
								<ButtonOutline
									className={classes.buttonOutline}
									key={category}

									textColour={ThemeData.colours.primaryFont}
									// backgroundColour={'clear'}
									backgroundColour={ThemeData.colours.clear}
									borderColour={ThemeData.colours.primaryFont}
									firstInColl={idx === 0}
									hoverTextColour={ThemeData.colours.black}
									isActive={showCategory === category}
									onClick={() => setShowCategory( category )}
								>
									{category}
								</ButtonOutline>
							)}
						</ButtonGroup>
					</div>
				}
				<motion.div
					initial={{opacity: 0 }}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.5}}
				>
					<PhotoGallery
						photos={filteredPhotos}
						onClick={openLightbox}
						direction='column'
						columns={3}
						margin={15}
					/>
					<ModalGateway>
						{isViewerOpen ? (
							<Modal onClose={closeLightbox}>
								<Carousel
									currentIndex={currentImage}
									views={
										galleryPhotos.map( x => ( {
											...x,
											key: x.src,
											srcset: x.srcset,
											caption: x.title,
										} ) )
									}
								/>
							</Modal>
						) : null}
					</ModalGateway>
				</motion.div>
			</div>
		</div>
	);
};



const Gallery = ( { ...props } ) => {

	const classes = useStyles( props );

	let queryToUse = QueryData.galleryPhotosMainGallery;
	// let queryToUse = '';
	let queryVars = {};
	if ( props.doOverrideUseFullPhotoGallery ) {
		queryToUse = QueryData.galleryPhotos;
	} else if ( props.withPhotosOfCategoryName ) {
		queryToUse = QueryData.galleryPhotosOfCategory;
		queryVars = {
			variables: {
				categoryName: props.withPhotosOfCategoryName
			}
		}
	}

	// console.log( "overrideUseFull", props.doOverrideUseFullPhotoGallery, "withPhotosOfCat", props.withPhotosOfCategoryName );

	const { loading, error, data } = useQuery( queryToUse, queryVars );

	if ( loading ) return RenderGallery( null, null, classes, props );
	if ( error ) return RenderGallery( null, error, classes, props );


	// console.log( 'gphData', data );


	return RenderGallery( data, null, classes, props );

}



Gallery.defaultProps = {
	noPadding: false,
	doOverrideUseFullPhotoGallery: false,
	withPhotosOfCategoryName: null,
	section: null
};



export default Gallery;