import { Breadcrumbs } from '@mui/material';
import ThemeData from 'data/ThemeData';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SavedHomesTitle from './SavedHomesTitle';
import HeaderComponents from "./HeaderComponents";
import HeaderContextData from "data/HeaderContextData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as BackArrow} from '../img/back-arrow.svg';


const BreadCrumbNav = (props) => {
	const useStyles = createUseStyles({
		header: {
			display: 'flex',
			// color: 'black',
			justifyContent: 'space-between',
			boxSizing: 'border-box',
			height: 'var( --header-height )',
			flexDirection: 'row',
			backgroundColor: ThemeData.colours.primaryHeaderBg,
			borderBottom: ThemeData.colours.paneBorders,
		},
		headerRight: {
			display: 'flex',
			alignItems: '',
			justifyContent: 'flex-end',
			...ThemeData.typography.nameplate,
		},
		mainMenuButton: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: ThemeData.colours.white,
			minWidth: 100,
			height: 100,
			cursor: 'pointer',
			background: 'no-repeat center',
			fontFamily: ThemeData.fonts.title,
			borderRight:`1px ${ThemeData.colours.white50} solid`,
			...ThemeData.ux.fillToLeft,
		},
		breadCrumbsWrapper: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			'& > ol > li': {
				marginLeft: 60,
				fontFamily: ThemeData.fonts.title,
			}
		},
		register: {
			flex: '0 0 auto',
			width: 175,
			lineHeight: '100px',
			...ThemeData.ux.fillToRight,
			borderLeft: `1px ${ThemeData.colours.white50} solid`,
			cursor: 'pointer',
			color: ThemeData.colours.white,
			'& p': {
				margin: 0,
			}
		},
		headerRightItem: {
			height: 'calc( var( --header-height ) - 1px )',
			lineHeight: 'var( --header-height )',
			'& > p': {
				margin: 0,
			},
		},
		savedHomes: {
			flex: '0 0 auto',
			width: 175,
			marginRight: 50,
			cursor: 'pointer',
			color: ThemeData.colours.white,
		},
		lastListItem: {
			color: `${ThemeData.colours.white} !important`,
			borderBottom: `3px solid ${ThemeData.colours.primaryAccent}`
		},
		fadeItem: {
			color: `${ThemeData.colours.white50} !important`,
			'&:hover': {
				cursor: 'pointer'
			}
		},
		backButton: {
			flex: '0 0 auto',
			cursor: 'pointer',
			paddingLeft: 40,

			color: ThemeData.colours.primaryFont,
			fontSize: 24,
		},
	})

	const location = useLocation();
	const history = useHistory();

	const proximationPathLocation = location.pathname.slice(0, -1);
	
	const [planNavState, setNavState] = useState({
		[proximationPathLocation]: ""
    });
	const [pathnames, setPathNames] = useState(['proximation'])
	
	useEffect(() => {
		props.unitDetailOpen && (props.selectedUnitData && props.selectedUnitData.units[0] && setNavState(
			{
				[proximationPathLocation]: "Daniels MPV 2",
				[`${location.pathname}plan${props.selectedUnitData.units[0].unit_plan_type.name}`]: `${props.selectedUnitData.units[0].unit_plan_type.name}`  
		}))
		props.unitDetailOpen && (props.selectedUnitData && props.selectedUnitData.units[0] && setPathNames(['proximation', `plan${props.selectedUnitData.units[0].unit_plan_type.name}`]))
	}, [props.selectedUnitData, props.unitDetailOpen])

    const classes = useStyles();
	const LinkRouter = (children) => (
		<div
			onClick={() =>  {pathnames.pop(); setNavState({[proximationPathLocation]: "Daniels MPV 2"}); setPathNames(pathnames); return props.setUnitDetailOpen(false)}}
			style={{ color: ThemeData.colours.white25 }} {...children} />
	  );


	const renderBackButton = () => {
		return props.unitDetailOpen ?
		<div
			onClick={() =>  {pathnames.pop(); setNavState({[proximationPathLocation]: "Daniels MPV 2"}); setPathNames(pathnames); return props.setUnitDetailOpen(false)}}
			className={`${classes.mainMenuButton}`}>
				<BackArrow fill={ThemeData.colours.white50}/>
		</div> :
		props.isViewStudySlideUpOpen ?
		<div
			onClick={() =>  props.setIsViewStudySlideUpOpenToClosed()}
			className={`${classes.mainMenuButton}`}>
				<BackArrow fill={ThemeData.colours.white50}/>
		</div> :
		props.isVirtualTourOpen ?
		<div
			onClick={() =>  props.setIsVirtualTourOpenToClosed()}
			className={`${classes.mainMenuButton}`}>
				<BackArrow fill={ThemeData.colours.white50}/>
		</div> :
		<div
			onClick={() => history.goBack()}
			className={`${classes.mainMenuButton}`}>
				<BackArrow fill={ThemeData.colours.white50}/>
		</div>
	}
    return (
		<HeaderComponents 
                    headerContext={HeaderContextData.contentPageHeaderContext} 
                    setIsUnitDetailOpen={props.setIsUnitDetailOpen}
					toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
					toggleIsRegisterOpen={props.toggleIsRegisterOpen}
                    backgroundColor={ThemeData.colours.primaryHeaderBg}
                >
					<div className={classes.backButtonWrapper}>
					<Link
						className={classes.backButton}
						to={'/'}
					>
                            <FontAwesomeIcon icon={faArrowLeft} /> BACK
					</Link>
					</div>
				</HeaderComponents>
    )
}

export default BreadCrumbNav;