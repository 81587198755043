import React, {
	// useState,
	// useRef,
	// useEffect,
} from "react";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";

import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";
import DistanceFilters from "./DistanceFilters";


const useStyles = createUseStyles(
	{
		wrapper: {
			width: '100%',
			height: '100%',
		},
		title: {
			fontFamily: ThemeData.fonts.title,

			letterSpacing: '0.15em',
			textTransform: 'uppercase',
		},
		category: {
			paddingTop: 10,

			lineHeight: '3em',

			"& $categoryTitle, & $poi": {
				borderRadius: '7px',
			},
		},
		categoryTitle: {
			paddingLeft: 10,

			fontFamily: ThemeData.fonts.title,

			fontSize: 12,
			letterSpacing: '0.15em',
			textTransform: 'uppercase',
		},
		poi: {
			display: 'flex',
			width: '100%',

			fontFamily: ThemeData.fonts.copy,

			fontSize: 16,
			letterSpacing: 0,
			textTransform: 'uppercase',
		},
		clickableItem: {
			cursor: 'pointer',
		},
		poiNumber: {
			paddingLeft: 10,

			flex: '0 0 auto',

			width: 30,

			letterSpacing: 0,
			textAlign: 'center',
		},
		poiName: {
			flex: '0 1 auto',
			fontWeight: 600,

			minWidth: 0,
		},
		content: {
			flex: '1 1 auto',
			color: ThemeData.colours.secondaryFont,
			height: '100%',
			fontFamily: ThemeData.fonts.copy,
			fontSize: '1.2em',
			letterSpacing: '0.0125em',
			lineHeight: '2em',

			overflowY: 'auto',

			...ThemeData.styles.thinScrollbar,
		},
		contentArea: {
			flex: '0 0 auto',

			'&::-webkit-scrollbar':{
				display: 'none'
			},
		}
	},
	{
		name: "NeighbourhoodPois",
	}
)

const highlightedPoiVariants = {
	highlighted: ( custom ) => ( {
		backgroundColor: `${custom}33`,
	} ),
	normal: ( custom ) => ( {
		backgroundColor: `${custom}00`,
	} ),
}

function RenderNeighbourhoodPois( classes, data, error, doShow, props ) {
	return (
		<>
		{/* <h3>{`Distance`.toUpperCase()}</h3> */}
			{/* <DistanceFilters 
				distance={props.distance} 
				toggleDistance={props.toggleDistance}
			/> */}
			<div className={`${classes.contentArea} ${classes.content}`}>
				<div className={classes.wrapper}>
					<div className={classes.title}>
						Categories
					</div>
					{data &&
						[...data.neighbourhoodLocationCategories]
							.sort( ( catA, catB ) => catA.order - catB.order )
							.map( nlCat => (
								<div className={classes.category} key={nlCat.id}>
									<motion.div
										className={`${classes.categoryTitle} ${classes.clickableItem}`}
										style={{ color: `${nlCat.colour}` }}
										animate={props.highlightCatPoi.highCatId === nlCat.order.toString() ? "highlighted" : "normal"}
										custom={nlCat.colour}
										variants={highlightedPoiVariants}
										onMouseEnter={() => {
											// mouseenter={() => {
											// console.log( "catME" );
											props.setHighlightCatPoi( {
												isHighActive: true,
												highCatId: nlCat.order.toString(),
												highPoiId: -1,
											} )
										}}
										onMouseLeave={() => {
											// mouseleave={() => {
											// console.log( "catML" );
											props.setHighlightCatPoi( {
												isHighActive: false,
												highCatId: -1,
												highPoiId: -1,
											} )
										}}
										onTap={() => props.setProcFlyTo( {
											doProcFlyTo: true,
											catid: nlCat.id,
											poiid: -1,
											// coordinates: [poi.longitude, poi.latitude],
										} )}
									>
										{nlCat.name}
										{( [...nlCat.neighbourhood_locations]
											.sort( ( poiA, poiB ) => poiA.number - poiB.number ) )
											.map( poi => (
												<motion.div
													className={`${classes.poi} ${classes.clickableItem}`}
													animate={props.highlightCatPoi.highPoiId === poi.number ? "highlighted" : "normal"}
													custom={nlCat.colour}
													variants={highlightedPoiVariants}
													key={poi.id}
													nlcid={nlCat.id}
													nlid={poi.id}
													onMouseEnter={() => props.setHighlightCatPoi( {
														isHighActive: true,
														highCatId: -1,
														highPoiId: poi.number,
													} )}
													onMouseLeave={() => props.setHighlightCatPoi( {
														isHighActive: false,
														highCatId: -1,
														highPoiId: -1,
													} )}
													onTap={() => props.setProcFlyTo( {
														doProcFlyTo: true,
														catid: -1,
														poiid: poi.number,
														coordinates: [poi.longitude, poi.latitude],
													} )}
									>
										<div className={classes.poiNumber}>{poi.number}</div>
										<div className={classes.poiName}>{poi.name}</div>
									</motion.div>
								) )}
						</motion.div>
					</div>
				) )}
			</div>
			</div>
		</>
	);
}

const ContentPageNeighbourhoodPois = ( { doShow, ...props } ) => {

	const classes = useStyles();
	const { loading, error, data } = useQuery( QueryData.neighbourhoodLocationCategories, {
		variables: {
			distance: props.distance,
		}
	}  );
	// if ( loading ) return RenderNeighbourhoodPois( classes, null, null, doShow, props );
	if ( error ) return RenderNeighbourhoodPois( classes, null, error, doShow, props );

	return !loading && data && RenderNeighbourhoodPois( classes, data, null, doShow, props );

};



export default ContentPageNeighbourhoodPois;