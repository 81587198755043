import React, {
	useLayoutEffect,
	useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import ButtonOutline from "./ButtonOutline";

import ThemeData from "data/ThemeData";
import useSavedHomes from "hooks/useSavedHomes";
import { BookMarkButton } from "./BookMarkButton";

const useStyles = createUseStyles(
	{
		wrapper: {
			height: 120,
			width: '100%',
			borderRadius: 4,
			display: 'flex',
			boxSizing: 'border-box',
			alignItems: 'center',
			flexDirection: 'row',
			color: ThemeData.colours.black,
			margin: '8px 0',
			textAlign: 'left',
			border: '1px solid #EEEDE6',
			justifyContent: 'space-between',
			'@media (min-width: 3800px)': {
				height: 360,
			}
		},
		selectedItem: {
			backgroundColor: ThemeData.colours.arcaneSteelBlue,
			border: `1px solid ${ThemeData.colours.primaryAccent}`
		},
		floorplanImg: {
			flex: '0 0 auto',
			width: 100,
			margin: '0 16px',
			backgroundColor: ThemeData.colours.clear,
			'@media (min-width: 3800px)':{
				width: '400px',
				height: '300px',
			},
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			color: ThemeData.colours.white75,

			textTransform: 'uppercase',
		},
		removeHomeButton: {
			flex: '0 0 auto',
			width: 48,
		},
		tableItem: {
			flex: '1 0 auto',
			width: 100,
			flexDirection: 'row'
		},
		moreDetailsButtonArea: {
			zIndex: 2,
			marginRight: 15
		},
		infoTextWrapper: {
			display: 'flex',
			flexDirection: 'row',
			minWidth: '45%',
			'@media (min-width: 3800px)':{
				fontSize: '40px',
				flexDirection: 'column',
			},
		},
		planAvailableWrapper: {
			display: 'table',
			textAlign: 'left',
		},
		planInfoWrapper: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 14,
			fontWeight: 'lighter',
			paddingTop: 5,
			minWidth: 200,
			'& > div' :{
				padding: '0 3px',
				fontWeight: 'lighter',
			},
			'@media (max-width: 1200px)':{
				fontSize: 11
			},
			'@media (min-width: 3800px)':{
				fontSize: '40px'
			},
		},
		planText: {
			fontSize: 16,
			fontWeight: 600,
			marginTop: 8,
			marginBottom: 4,
			'@media (min-width: 3800px)':{
				fontSize: '45px',
				marginTop: 12,
			},
		},
		availabilityText: {
			height: 20,
			backgroundColor: ThemeData.colours.arcaneEmeraldGreen,
			borderRadius: 4,
			padding: '3px 4px 2px 4px',

			marginBottom: 8,
			'@media (min-width: 3800px)':{
				height: 20,
				backgroundColor: ThemeData.colours.arcaneEmeraldGreen,
				borderRadius: 4,
				padding: '10px 10px 10px 10px',
				fontSize:'30px',
			},
		},
		moreDetails: {
			borderRadius: 4,
			'@media (max-width: 1200px)':{
				fontSize: 9
			},
			'@media (min-width: 3800px)':{
				fontSize: '36px',
				padding: '20px 20px 20px 20px',
			},
		}
	},
	{
		name: "UnitDetailTableItem",
	}
)



const UnitDetailTableItem = ( { dataUnit, ...props } ) => {
	const classes = useStyles();
	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();
	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
	};
	/* Measuring screenwidth to resize bookmark components because height/width must be adjusted
	via. props. */
	const useWindowSize = () => {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
		  function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		  }
		  window.addEventListener('resize', updateSize);
		  updateSize();
		  return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	  }
	  const screenWidth = useWindowSize()[0];

	return (
		<>
			<div
				id={dataUnit.custom_id === props.selectedUnitId ? `unit-detail-tableItem-${props.itemIdx}` : `unit-detail-tableItem-active-${props.itemIdx}`}
				className={
					`${classes.wrapper
					} ${props.itemIdx % 2 === 0 ?
						classes.rowBGColourOne :
						classes.rowBGColourTwo
					} ${dataUnit.custom_id === props.selectedUnitId ? classes.selectedItem : ''
					}`}
				onClick={() => {
					props.isToAllowSelection && props.setSelectedUnitId( dataUnit.custom_id );
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
					<img className={classes.floorplanImg} src={dataUnit.floorplan_image.url} alt="floor_plan_img"/>
					<div className={classes.infoTextWrapper}>
						<div className={classes.planAvailableWrapper}>
							{dataUnit.unit_availability_state && <span className={classes.availabilityText}>{dataUnit.unit_availability_state.value.toUpperCase()}</span>}
							<div className={classes.planText}>{dataUnit.unit_plan_type.name}</div>
							<div className={classes.planInfoWrapper}>
								<div>{dataUnit.baths}</div>
									&middot;
								<div>{dataUnit.interior_sqft} sqft</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
					<div className={classes.moreDetailsButtonArea}>
						<ButtonOutline
							id={`more-details-button-list-view-${props.itemIdx}`}
							className={classes.moreDetails}
							label="Details"
							padding={10}
							textColour={ThemeData.colours.primaryAccent}
							hoverTextColour={ThemeData.colours.white}
							border="1px solid #EEEDE6"
							backgroundColour={ThemeData.colours.white}
							onClick={props.toggleIsUnitDetailOpen}
							borderRadius={4}
						/>
					</div>
					{/* <BookMarkButton
						dataUnit={dataUnit}
						hooks={hooks}
						height={screenWidth >= 3800 ? 60 : 30}
						width={screenWidth >= 3800 ? 60 : 30}
						margin={'0px 20px 0px 20px'}
					/> */}
				</div>
			</div>
		</>
	);
}



UnitDetailTableItem.defaultProps = {
	isToAllowSelection: false,
}



export default UnitDetailTableItem;