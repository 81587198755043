export const ConstantData = {
	contentCardForms: {
		oneUp: "oneUp",
		twoUpHoriz: "twoUpHoriz",
		twoUpVert: "twoUpVert",
		fourUp: "fourUp",
		sixUp: "sixUp",
	},
	contentPageCustomFunctions: {
		interactiveMap: "interactiveMap",
		photoGallery: "photoGallery",
		floorplans: "floorplans",
		photosOnly: "photosOnly",
		virtualTour: "virtualTour",
		enjoyTheView: "enjoyTheView",
		colourSchemes: "colourSchemes",
		homeAmenityFeatures: "homeAmenityFeatures",
		craftPartners: "craftPartners",
		galleryContentPage: "galleryContentPage",
		showhomeGallery: "showhomeGallery",
		externalLink: "externalLink",
	},
	dataFormattingFunctions: {
		prices: value => `$${value.toLocaleString()}`,
	},
	imgSizes: {
		raw: 'raw',
		xlarge: 'xlarge',
		large: 'large',
		medium: 'medium',
		small: 'small',
		xsmall: 'xsmall',
	},
	localStorageKeys: {
		savedHomes: "savedHomes",
		siteFeatureToggles: "siteFeatureToggles",
		registration: "registration",
		unitsFilter: "unitsFilter",
		unitsFilterResult: "unitsFilterResult",
		unitFiltersList: "unitFiltersList"
	},
	galleryPhotoDefaultCategories: {
		all: "all",
	},
	gqlQueryToSubqueryTypesMap: {
		unitFloors: "unit_floor",
		unitBuildings: "unit_building",
		unitHomeTypes: "unit_home_type",
		unitPlanTypes: "unit_plan_type",
		unitExposures: "unit_exposures",
	},
	scrollIconDir: {
		vertical: "vertical",
		horizontal: "horizontal",
	},
	sectionCustomFunctions: {
		proxima: "proxima",
		floorplans: "floorplans",
		gallery: "gallery",
		map: "map",
	},
	TopMenu: {
		defaultSectionContentToDisplay: "default",
	},

	unitFilterViewModes: {
		card: "card",
		list: "list",
		compare: "compare",
	},
	unitFilterTypes: {
		checkedList: "checkedList",
		range: "range",
	},
	webglViewTypes: {
		byFloorplateDetailed: "byFloorplateDetailed",
		byFloorplateSimple: "byFloorplateSimple",
		building3DModel: "building3DModel",
	},
	webglWindowMessageTypes: {
		webglHotspotClicked: "webglHotspotClicked",
	},
	cookieCopy: { 
		cookie: <div>Cookies make our platform work properly and securely. By allowing cookies, you will get the best experience with developer enriched content and relevant services."</div>,
		privacyPolicy: <div>You can always find more information by visiting our “Privacy Policy” in the main menu. View Proxima’s <b style={{ cursor: 'pointer' }}>Privacy and Cookie Policy</b>.</div>
	},
	disclaimerData: {
		disclaimer_2: 'All areas and stated room dimensions are approximate. Note: Actual usable floor space my vary from the stated floor area. Floor area is measured in accordance with the HCRA directive on floor area calculations. Size and location of windows may vary. The purchaser acknowledges that the actual unit purchased may be a reversed layout to the plan shown.',
		disclaimer_3: ' Illustration is artist’s concept. E. & O. E. Outdoor area will vary depending on suite location within the building. Please speak with a Sales Representative for details.',
		disclaimer_4: `Renderings are artists' concept only.`,
		disclaimer_5: 'Prices are subject to change without notice and are subject to availability. E.&O.E. Prices do not include applicable taxes.',
		disclaimer_6: 'Marketing and Sales by LNG Studios. *subagent sales team member own brokerage name on business cards and emails.'
	}
};

export default ConstantData;