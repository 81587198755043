import React, {
	useLayoutEffect,
	useState
} from "react";
import { useLocation } from "react-router-dom";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";

import {
	createUseStyles
} from "react-jss";

import {
	motion,
} from "framer-motion";


import useSavedHomes from "hooks/useSavedHomes";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoPlanNameTitle from "components/UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";

import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',
			paddingTop: 5,
			boxSizing: 'border-box',
			...ThemeData.styles.hoverCard,
			// border: `5px rgba( 1, 1, 1, 0 ) solid`,
		},
		selectedUnitHighlight: {
			border: `2px ${ThemeData.colours.primaryAccent} solid`,
			// boxShadow: `inset 0 0 0 5px ${ThemeData.colours.primaryAccent}`,
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 190,
			borderBottom: `1px ${ThemeData.colours.blackBg15} solid`,
			cursor: 'pointer',
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
		},
		details: {
			display: 'flex',
			justifyContent: 'center',
			position: 'relative',
			minHeight: 63,
			padding: '20px 20px 5px 20px',
			cursor: 'pointer',
			textAlign: 'left',
			'& p': {
			},
		},
		unitInfoPlanNameTitle: {
			margin: 0,
			padding: 0,
			color: ThemeData.colours.black,
			fontFamily: ThemeData.fonts.title,
			fontSize: '16px',
			maxWidth: '280px',
			fontWeight: 600,
			'@media (min-width: 3800px)':{
				fontSize: '40px',
				height: '120px',
				width: '100%',
				maxWidth: '1000px'
			},
		},
		unitInfoLine: {
			marginTop: 10,
			color: ThemeData.colours.grey,
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: '14px',
			maxWidth: '280px',
			letterSpacing: '0.05em',
			'@media (min-width: 3800px)':{
				fontSize: '36px',
				maxWidth: '1000px',
			},
		},
		buttonArea: {
			paddingInline: 20,
			paddingBlockEnd: 20,
		},
		moreDetailsButtonArea: {
			borderTop: `1px ${ThemeData.colours.blackBg15} solid`,
		},
		image: {
			height: 187,
			'@media (min-width: 3800px)':{
				objectFit: 'cover',
				width: '800px',
				height: '400px',
			},
		},
		moreDetailButton: {
			'@media (min-width: 3800px)':{
				fontSize: '32px',
				fontWeight: 900,
				padding: 40
			},
		},
		secondaryButton: {
			marginTop: 20,
			'@media (min-width: 3800px)':{
				fontSize: '32px',
				padding: 40,
				marginTop: 30
			},
		},
		bookMark: {
			'@media (min-width: 3800px)':{
				width: 60,
				height: 60
			},
		}
	},
	{
		name: "UnitDetailCardGeneric",
	}
)

function RenderUnitDetailCardGeneric( dataUnit, classes, error, toggleUnitDetailPaneFcn, hooks, location, props ) {
	const doDisplayCard = true; 
	const apiUri = NetData.apiUri;

	/* Measuring screenwidth to resize bookmark components because height/width must be adjusted
	via. props. */
	const useWindowSize = () => {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
		  function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		  }
		  window.addEventListener('resize', updateSize);
		  updateSize();
		  return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	  }
	  const screenWidth = useWindowSize()[0];

	return (
		<motion.div
			className={
				`${props.className
				} ${classes.wrapper
				} ${doDisplayCard ? "" : classes.hidden
				} ${dataUnit.custom_id === props.selectedUnitId && props.isToAllowSelection ? classes.selectedUnitHighlight : ""
				}`}
			onClick={() => 
				props.setSelectedUnitId( dataUnit.custom_id )
			}
		>
			<div className={classes.floorplanImage}>
				{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
					<img
						src={`${NetData.imgUri( dataUnit.unit_plan_type.floorplan_image.formats && dataUnit.unit_plan_type.floorplan_image.formats.small ?
							dataUnit.unit_plan_type.floorplan_image.formats.small.url :
							dataUnit.unit_plan_type.floorplan_image.url )} `}
						alt={dataUnit.unit_plan_type.floorplan_image.alternativeText}
						className={classes.image}
						onClick={() => {
							toggleUnitDetailPaneFcn();
							sendGoogleAnalyticsEvent(
								`Floor Plan View - ${location.pathname}`,
								`User Clicks ${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
								`${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
								`${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`
							);
						}
					}
					/>
				}
				{( !dataUnit || !dataUnit.unit_plan_type || !dataUnit.unit_plan_type.floorplan_image ) &&
					<img
						src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
						alt='floorplan placeholder'
						width={331}
						height={187}
					/>
				}
			</div>
			<div className={classes.details}
				onClick={() => toggleUnitDetailPaneFcn()}
			>
				<div>
					{props.unitCardActionButton}
					<UnitInfoPlanNameTitle
						data={dataUnit}
						doShowUnitNumber={true}
						className={classes.unitInfoPlanNameTitle}
					/>
					<div className={classes.unitInfoLine}>{dataUnit && <UnitInfoLine
						data={dataUnit}
					/>}</div>
				</div>
			{/* <BookMarkButton
				hooks={hooks}
				dataUnit={dataUnit}
				height={screenWidth >= 3800 ? 60 : 30}
				width={screenWidth >= 3800 ? 60 : 30}
			/> */}
			</div>
			<div className={classes.buttonArea}>
				<ButtonGroup
					justifyContent='center'
				>
					{/* <ButtonOutline
						id="enjoy-the-views"
						label="Enjoy the View"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						to="."
						onClick={props.toggleIsViewStudySlideUpOpenFcn}
						firstInColl
						className={classes.secondaryButton}
					/>
					<ButtonOutline
						id="take-the-tour"
						label="Take the Tour"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						onClick={() => props.toggleVirtualTourPaneFcn()}
						className={classes.secondaryButton}
						to="."
					/> */}
				</ButtonGroup>
			</div>
			<div className={classes.moreDetailsButtonArea}>
				<ButtonOutline
					id={`more-details-button-unit-card-${dataUnit.id}`}
					label="More Details"
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.neuGray}
					onClick={() => toggleUnitDetailPaneFcn()}
					className={classes.moreDetailButton}
				/>
			</div>
		</motion.div>
	);
}

function UnitDetailCardGeneric( { dataUnit, toggleUnitDetailPaneFcn, ...props } ) {

	const classes = useStyles();
	
	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	const location = useLocation();

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes
	}

	return RenderUnitDetailCardGeneric( dataUnit, classes, null, toggleUnitDetailPaneFcn, hooks, location, props );
}



UnitDetailCardGeneric.defaultProps = {
	isToAllowSelection: false,
}

export default UnitDetailCardGeneric;