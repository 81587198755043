import React from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
} from "@apollo/client";

import {
	motion,
} from "framer-motion";

import ThemeData from "data/ThemeData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";
import {ReactComponent as XMedium} from "../img/x_medium.svg";
import Floorplans from "./Floorplans";
import { BookMarkButton } from "./BookMarkButton";
import HeaderComponents from "./HeaderComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useOpenClosePane from "hooks/useOpenClosePane";


const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'fixed',
			top: '50%',
			left: '50%',
			width: 'calc( 100vw )',
			height: 'calc( 100vh )',
			boxSizing: 'border-box',
			overflow: 'clip',
			color: ThemeData.colours.secondaryFont,
			backgroundColor: ThemeData.colours.black,
			letterSpacing: '.025em',
		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,
			color: ThemeData.colours.primaryAccent08,
			fontFamily: ThemeData.fonts.copy,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%',
		},
		closeX: {
			position: 'absolute',
			top: 20,
			right: 20,
			cursor: 'pointer',
		},
		title: {
			paddingLeft: 25,
			fontSize: 24,
			textAlign: 'left',
			textTransform: 'uppercase',
		},
		top: {
			flex: '0 0 auto',
			height: 100
		},
		filterButton: {
			borderRadius: 50,
		},
		middle: {
			flex: '1 0 auto',
			maxHeight: 'calc( 100vh - 100px )',
			overflowX: 'clip',
			overflowY: 'auto',
			...ThemeData.styles.thinScrollbar,
		},
		bottom: {
			flex: '0 0 auto',
			boxSizing: 'border-box',
			paddingTop: 25,
			height: 130,
			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
		bottomButton: {
			fontSize: 14,
			letterSpacing: '.1em',
		},
		filterCheckboxes: {
			color: ThemeData.colours.white,
		},
		backButton: {
			flex: '0 0 auto',
			cursor: 'pointer',
			paddingLeft: 40,
			color: ThemeData.colours.neuDarkGreen,
			fontSize: 24,
		}
	},
	{
		name: "SavedHomesCompareList",
	},
)



const wrapperAnimVariants = {
	open: {
		x: "-50%",
		y: "-50%",
		opacity: 1,
		zIndex: 4,
		display: 'block',
	},
	closed: {
		x: "-50%",
		y: "-50%",
		opacity: 0,
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}



const RenderSavedHomesCompareList = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {
	if ( error ) {
		console.log( "RenderSavedHomesSlideout", error.message );
	}

	function stopPropagation( e ) {
		e.stopPropagation();
	}

	const {
		isVirtualTourOpen,
		setIsVirtualTourOpenToClosed,
		toggleIsVirtualTourOpen,
	} = useOpenClosePane( "VirtualTour" )

	const {
		isFloorplansViewStudySlideUpOpen,
		setIsFloorplansViewStudySlideUpOpenToClosed,
		toggleIsFloorplansViewStudySlideUpOpen,
	} = useOpenClosePane( "FloorplansViewStudySlideUp" )

	const viewStudyUrl = useQuery( QueryData.siteConfiguration);

	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: "tween",
				bounce: .25,
				duration: .5,
			}}
			onMouseDown={( e ) => stopPropagation( e )}
			onMouseUp={( e ) => stopPropagation( e )}
		>
			<div className={classes.contentContainer}>
			{props.isUnitDetailOpen ?	
				<HeaderComponents display='none'>
					<div className={classes.backButtonWrapper}>
						<div
							className={classes.backButton}
							onClick={() => props.toggleIsUnitDetailOpen(false)}
						>
							<FontAwesomeIcon icon={faArrowLeft} /> BACK
						</div>
					</div>
				</HeaderComponents> 
				:
			isFloorplansViewStudySlideUpOpen ?
				<HeaderComponents display='none'>
					<div className={classes.backButtonWrapper}>
						<div
							className={classes.backButton}
							onClick={() => setIsFloorplansViewStudySlideUpOpenToClosed(false)}
						>
							<FontAwesomeIcon icon={faArrowLeft} /> BACK
						</div>
					</div>
				</HeaderComponents> 
				:
			isVirtualTourOpen ?
				<HeaderComponents display='none'>
					<div className={classes.backButtonWrapper}>
						<div
							className={classes.backButton}
							onClick={() => setIsVirtualTourOpenToClosed(false)}
						>
							<FontAwesomeIcon icon={faArrowLeft} /> BACK
						</div>
					</div>
				</HeaderComponents> 
				:
				<div className={classes.top}>
					<h4 className={classes.title}>Select Homes To Compare</h4>
					<div
						className={classes.closeX}
						onClick={() => toggleIsOpen()}>
						<XMedium stroke={ThemeData.colours.primaryAccent} />
					</div>
				</div>}
				<div className={classes.middle}>
					<Floorplans
						isToAllowCompare={false}
						overrideBackgroundColor={ThemeData.colours.secondaryBgHighContrast}
						isViewStudySlideUpOpen={isFloorplansViewStudySlideUpOpen}
						toggleIsViewStudySlideUpOpen={toggleIsFloorplansViewStudySlideUpOpen}
						isVirtualTourOpen={isVirtualTourOpen}
						toggleIsVirtualTourOpen={toggleIsVirtualTourOpen}
						viewUrl={viewStudyUrl.data && viewStudyUrl.data.siteConfiguration.view_study_iframe_url}
						userLocation={'save-and-compare'}
						unitCardActionButtonProps={{
							onClickDataUnitArgName: "id",
							isToShow: true,
							altText: "Save Home",
							textColourInactive: ThemeData.colours.primaryAccent,
							textColourActive: ThemeData.colours.primaryFontHighestContrast,
							backgroundColourInactive: ThemeData.colours.cardBg,
							backgroundColourActive: ThemeData.colours.primaryAccent,
							borderColour: ThemeData.colours.grey25,
							onClick: dataUnitId => hooks.toggleSavedHome( dataUnitId ),
							isActiveFcn: dataUnitId => hooks.savedHomesIncludes( dataUnitId ),
							contentInactive: dataUnit => <BookMarkButton hooks={hooks} dataUnit={dataUnit} height={40} width={30} />,
							contentActive: dataUnit => <BookMarkButton hooks={hooks} dataUnit={dataUnit} height={40} width={30} />,
						}}
					/>
				</div>
			</div>
		</motion.div>
	)
}



const SavedHomesCompareList = ( { isOpen, toggleIsOpen, ...props } ) => {
	const classes = useStyles();

	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();
	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
	};

	const { loading, error, data } = useQuery( QueryData.units );

	if ( loading ) return RenderSavedHomesCompareList( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderSavedHomesCompareList( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	return RenderSavedHomesCompareList( classes, data, null, isOpen, toggleIsOpen, hooks, props );

}



export default SavedHomesCompareList;