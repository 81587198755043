import React from "react";
import { useLocation } from "react-router-dom";
import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";

import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";

import ThemeData from "../data/ThemeData";

import useRegistration from "hooks/useRegistration";
import useSendEmail from "hooks/useSendEmail";

import DialogGeneric from "./DialogGeneric";
import { useQuery } from "@apollo/client";
import QueryData from "data/QueryData";

const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'fixed',
			top: '45%',
			left: '50%',
			padding: 30,
			paddingLeft: 50,
			paddingRight: 50,
			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.primaryBg,
			textAlign: 'left',
			width: '700px',
			...ThemeData.styles.hoverPaneBoxShadow,
		},
		closeX: {
			position: 'absolute',
			top: 20,
			right: 20,

			cursor: 'pointer',
		},
		title: {
			fontSize: 30,
		},
		label: {
			display: 'inline-block',
			marginTop: 40,

			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		input: {
			width: 335,

			border: 'none',
			borderBottom: `1px ${ThemeData.colours.primaryAccent} solid`,

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.primaryBg,

			fontFamily: ThemeData.fonts.copy,
			fontSize: 16,

			"&:focus, &:active": {
				outline: 'none',

				border: 'none',
				borderBottom: `1px ${ThemeData.colours.primaryAccent} solid`,
			},
		},
		emailInput: {
			width: 695,
		},
		firstName: {
		},
		lastName: {
			paddingLeft: 20,
		},
		email: {
		},
		registerFooter:{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between'
		},
		submitButton: {
			width: 'auto !important',
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 16,
		},
		cancelButton: {
			marginTop: 50,
			backgroundColor: 'transparent',
			color: ThemeData.colours.white,
			border: `none`,
			cursor: 'pointer',
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: 16,
			marginRight: 30,
		},
		buttons: {
			display: 'inline-flex',
			float: 'right',
			justifyContent: 'space-between'
		},
		disclaimer: {
			fontSize: 6,
			maxWidth: '50%',
			marginTop: 50
		}
	},
	{
		name: "Register",
	}
)



const SubmitButton = styled( Button )( {
	height: 60,
	width: 90,
	marginTop: 50,
	color: ThemeData.colours.white,
	border: `${ThemeData.colours.white} solid`,
	borderWidth: '0.1px',
	borderRadius: '0px !important',
	fontFamily: ThemeData.fonts.titleSub,
	fontSize: 16,
	'&:hover': {
		border: `1px ${ThemeData.colours.primaryAccent} solid`,
	},
} );



function RenderRegister( classes, data, error, doShow, props, children ) {
	const { registration, setFirstName, setLastName, setEmail } = useRegistration();
	const { sendSavedHomesEmail } = useSendEmail();
	const handleSubmit = ( e ) => {

		e.preventDefault();

		sendGoogleAnalyticsEvent(
			'Send Saved Homes PDF to Email',
			`User Emails PDF to ${registration.fName} ${registration.lName}`,
			`${registration.fName} ${registration.lName}`,
			`${registration.fName} ${registration.lName}`
		);

		sendSavedHomesEmail( `${registration.fName} ${registration.lName}`, registration.email );

		props.toggleIsOpen();
	};


	return (
		<DialogGeneric
			isDialogOpen={doShow}
			setIsDialogOpenToClosed={props.toggleIsOpen}
			ariaLabelledBy="email-dialog-title"
			ariaDescribedBy="email-dialog-confirmation-action-button"
		>
			<motion.div
				id='register-modal'
				className={classes.wrapper}
				initial={"dontShow"}
				animate={doShow ? "show" : "dontShow"}
				variants={{
					show: {
						x: '-50%',
						y: '-50%',
						zIndex: 9999,
						display: 'block',
					},
					dontShow: {
						x: "100%",
						y: '-50%',
						display: 'block',
						transitionEnd: {
							zIndex: -2,
							display: 'none',
						}
					},
				}}
				transition={{
					duration: .5,
				}}
			>
				<div
					id={"email-dialog-title"}
					className={classes.title}>
					{props.titleText}
				</div>
				<form onSubmit={handleSubmit}>
					<label className={`${classes.label} ${classes.firstName}`}>
						First Name:<br /><br />
						<input id='register-first-name' className={classes.input} value={registration.fName} onChange={evt => setFirstName( evt.target.value )}></input>
					</label>
					<label className={`${classes.label} ${classes.lastName}`}>
						Last Name:<br /><br />
						<input id='register-last-name' className={classes.input} value={registration.lName} onChange={evt => setLastName( evt.target.value )}></input>
					</label><br />
					<label className={`${classes.label} ${classes.email}`}>
						Email:<br /><br />
						<input id='register-email' className={`${classes.input} ${classes.emailInput}`} value={registration.email} onChange={evt => setEmail( evt.target.value )}></input>
					</label><br />
					<div className={classes.registerFooter}>
						<div className={classes.disclaimer}>
							<p>All areas and stated room dimensions are approximate. Note: Actual usable floor space my vary from the stated floor area. Floor area is measured in accordance with the HCRA directive on floor area calculations. Size and location of windows may vary. The purchaser acknowledges that the actual unit purchased may be a reversed layout to the plan shown. Illustration is artist’s concept. E. & O. E. Outdoor area will vary depending on suite location within the building. Please speak with a Sales Representative for details.</p>
						</div>
					<div className={classes.buttons}>
						<button
							id='cancel-button'
							type='button'
							className={classes.cancelButton}
							onClick={props.toggleIsOpen}
						>
							CANCEL
						</button>
						<SubmitButton
							id="email-dialog-confirmation-action-button"
							className={classes.submitButton}
							sx={{
								...classes.submitButton,
							}}
							type='submit'
							value='Send Email'
							variant='outlined'
						>
							{props.buttonText}
						</SubmitButton>
					</div>
					</div>
				</form>
			</motion.div>
		</DialogGeneric>
	);
}

const Register = ( { doShow, children, ...props } ) => {

	const classes = useStyles();

	const { data } = useQuery( QueryData.navQuery );
	return RenderRegister( classes, data, null, doShow, props, children );

};



Register.defaultProps = {
	titleText: "Send PDF to email",
	buttonText: "Send",
}



export default Register;